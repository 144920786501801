import {useEffect , useState} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Box , Chip , Button } from '@mui/material';
import './Partials.scss'
import AutorenewIcon from '@mui/icons-material/Autorenew';


const ChipsFiltered = ({filterData , setFilterData , addParamsUrl  }) => {

    useEffect(()=>{
        let chips = []
        Object.keys(filterData).forEach((key)=>{
            if( key !== 'start_date' && key !== 'end_date' && filterData[key] !== '' ){
                chips.push({label: filterData[key] , key: key})
                return;
            }
        })
        setChipData(chips)
    },[filterData])

    const [chipData , setChipData] = useState([])

    const clearParams = {
        market: '',
        region: '',
        campaign: '',
        format: '',
        advertiser: ''
    }

    const handleDelete = (chipToDelete) => () => {
        const dataUpdate = {
            ...filterData,
            [chipToDelete.key] : ''
        }
        setFilterData(dataUpdate)
        addParamsUrl(dataUpdate)
    };

    const handleDeleteAll = () =>{
        const dataUpdate = {
            ...filterData,
            ...clearParams
        }
        setFilterData(dataUpdate)
        addParamsUrl(dataUpdate)
    }

 
    
    return (
        <Box className='chips-filter-container'>
            {
                <>
                    {chipData.map((data , index) => {
                        return (
                            <Chip
                                className='chip-custom'
                                variant='outlined'
                                label={data.label}
                                key={index}
                                color='info'
                                deleteIcon={<ClearIcon />}
                                onDelete={handleDelete(data)}
                            />
                        );
                    })}
                    {
                    chipData.length ? 
                        <Button onClick={handleDeleteAll} variant="outlined" className='button-clear' color='white' endIcon={<AutorenewIcon />}>
                            Clear All
                        </Button>
                    : null
                    }
                </>
            }
        </Box>
    );
}

export default ChipsFiltered