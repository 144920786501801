import React, {useState, useContext, useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import './TableAllCreatives.scss'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Pagination } from "@mui/material";
import { useParams , useNavigate , Navigate , Link } from 'react-router-dom'
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from './images/search-icon.svg'
import FilterIcon from './images/filter-icon.svg'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ModalFilter from "../Partials/ModalFilter";
import { useSearchParams } from "react-router-dom";
import ChipsFiltered from "../Partials/ChipsFiltered";
import dayjs from 'dayjs';


const TableAllCreatives = ({creatives , withCRUDOperations , openIframeCreative , setCurrentCreative , duplicateCreative , setOpenModalDelete}) => {
   
    const [searchParams, setSearchParams] = useSearchParams();

    let initialFilter = {
      start_date: '',
      end_date: '',
      advertiser: '',
      market: '',
      campaign: '',
      region: '',
      format: ''
    }

    const [stateSearchTerm, setSearchTerm] = useState('');
    const [filterData , setFilterData] = useState(initialFilter)
    const [ globalFilterState , setGlobalFilterState ] = useState({
      chipUpdated: false,
      isFilterActive: false
    })

    const [sortOrder, setSortOrder] = useState({sort: 'desc' , type:'date' , column: 'date'});
    const [filteredRows , setFilteredRows]  = useState([...creatives.allCreatives]);
    // const [creativesFiltered , setCreativesFiltered] = useState([])
    const [modalFilter , setModalFilter] = useState(false)

    // Definir el estado para la página actual
    const [page, setPage] = useState(0);
    // Definir la cantidad de filas por página
    const rowsPerPage = 10;

    useEffect(()=>{
      for (const [key, value] of searchParams.entries()) {
          if( initialFilter.hasOwnProperty(key) ){
              if( key === "start_date" || key === 'end_date' ){
                handleDate( dayjs(value) , key )
              }
              initialFilter[key] = value;
          }
      }
      setFilterData({...initialFilter})
    }, [])

    useEffect(()=>{
      applyFilter()
    } , [creatives , filterData])

    const handleDate = ( date , type ) => {
      setFilterData({
        ...filterData,
        [type] : date.format('YYYY-MM-DD')
      })
    }

    const makeOrder = (sortOrder) =>{
      if(sortOrder.type === 'date'){
        filteredRows?.sort((a, b) => {
          const dateA = new Date(a.date).toISOString();
          const dateB = new Date(b.date).toISOString();
          return sortOrder.sort === "asc" ? dateA.localeCompare(dateB) : dateB.localeCompare(dateA);
        });
      }else{
        filteredRows?.sort((a,b)=>{
          const nameA = a[sortOrder.column].toLowerCase()
          const nameB = b[sortOrder.column].toLowerCase()

          if(sortOrder.sort === "asc"){
            if(nameA < nameB){
              return -1;
            }

            if(nameA > nameB){
              return 1;
            }
          }else{
            if(nameA > nameB){
              return -1;
            }

            if(nameA < nameB){
              return 1;
            }
          }

          return 0;
        });
      }
      setFilteredRows([...filteredRows])
    }

  const applyFilter = () => {
    
    const {allCreatives} = creatives;

    let withDates = filterData.start_date !== '' && filterData.end_date !== '' ? true : false 

    const creativesFilteredBySearch = allCreatives?.filter((creative) => {
      return (
        creative.advertiser.toLowerCase().includes(stateSearchTerm.toLowerCase()) ||
        creative.campaign.toLowerCase().includes(stateSearchTerm.toLowerCase()) ||
        creative.market.toLowerCase().includes(stateSearchTerm.toLowerCase()) || 
        creative.region.toLowerCase().includes(stateSearchTerm.toLowerCase()) ||
        creative.format.toLowerCase().includes(stateSearchTerm.toLowerCase()) 
      )
    })

    const creativesFilteredByDate = creativesFilteredBySearch?.filter((creative) =>{
      if(withDates){
        return dayjs(creative.date).isBetween(filterData.start_date , filterData.end_date)
      }else{
        return true
      }
    })

    const creativesFilteredByFilter = creativesFilteredByDate?.filter((creative) => {
      return (
        creative.advertiser.toLowerCase().includes(filterData.advertiser.toLowerCase()) &&
        creative.campaign.toLowerCase().includes(filterData.campaign.toLowerCase()) &&
        creative.market.toLowerCase().includes(filterData.market.toLowerCase()) && 
        creative.region.toLowerCase().includes(filterData.region.toLowerCase()) &&
        creative.format.toLowerCase().includes(filterData.format.toLowerCase()) 
      )
    })
    
    addParamsUrl({...filterData})
    setFilteredRows(creativesFilteredByFilter)

  }

    const {creativesSlider, allCreatives} = creatives;

    const handleSort = (column , type = 'alphabetical') => {
      let newSortOrder = sortOrder.sort === "asc" ? "desc" : "asc";
      if( sortOrder.column !== column ){
         newSortOrder = 'asc'
      }
      setSortOrder({sort: newSortOrder , type: type , column:column})
      makeOrder({sort: newSortOrder , type: type , column:column})
    };

    const addParamsUrl = ( params = {}) =>{
      let query = {}
      let additionalParams = {}
      let paramsIgnore = ['market' , 'region' , 'campaign' , 'advertiser' , 'search' , 'format' , 'start_date' , 'end_date']
      for (const [key, value] of searchParams.entries()) {
          if( !paramsIgnore.includes(key) ){
            additionalParams[key] = value
          }
      }
      Object.keys(params).forEach((key)=>{
        if( params[key] !== '' ){
          query[key] = params[key]
        }
      })
      setSearchParams({
        ...query,
        ...additionalParams
      })
    }

    // Filtra los resultados según el término de búsqueda

    // Calcular la cantidad de páginas en función de la cantidad total de filas
    const pageCount = Math.ceil(filteredRows?.length / rowsPerPage);

    // Definir el índice de la primera y última fila en función de la página actual y la cantidad de filas por página
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, allCreatives?.length);

     // Obtener solo las filas que deben mostrarse en la página actual
     const pageRows = filteredRows?.slice(startIndex, endIndex);

    // Manejar el cambio de página
    const handlePageChange = (event, value) => {
        setPage(value - 1);
    };

    const sendDataToModal = (creative) => {
      openIframeCreative(creative)
    }
    const handleLiveDemoClick = (creativeId) => {
      const url = `${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creativeId}`;
      window.open(url, '_blank');
    }

    const resetFilter = () => {
      const { allCreatives } = creatives
      addParamsUrl({})
      setFilterData(initialFilter)
      // setGlobalFilterState({...globalFilterState , isFilterActive: false})
      setFilteredRows(allCreatives)
    }

    return (
        <>
        <Box className="Filter">
          <Box className="Filter__search">
            <img src={SearchIcon}></img>
            <TextField className="Filter__search-textField" label="Search" variant="filled"
    onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={()=>applyFilter()} />
            <Button onClick={()=>setModalFilter(true)}><img src={FilterIcon}></img>Filter</Button>
          </Box>
          <ChipsFiltered 
            addParamsUrl={addParamsUrl} 
            setFilterData={setFilterData} 
            filterData={filterData} 
            globalFilterState={globalFilterState}
          />
        </Box>
        <TableContainer sx={{ minHeight: 640 }} className="TableAllCreatives" component={Paper}>
          <Table  aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Preview</TableCell>
                <TableCell align="left" onClick={()=>handleSort('date' , 'date')}>
                   <span className={sortOrder.sort === "asc" && sortOrder.column === 'date' ? "arrow arrow-up" : "arrow arrow-down"}>Date</span> 
                </TableCell>
                <TableCell align="left" onClick={()=>handleSort('advertiser')}>
                  <span className={sortOrder.sort === "asc" && sortOrder.column === 'advertiser' ? "arrow arrow-up" : "arrow arrow-down"}>Advertiser</span>
                </TableCell>
                <TableCell align="left" style={{width: "200px"}} onClick={()=>handleSort('campaign')}>
                  <span className={sortOrder.sort === "asc" && sortOrder.column === 'campaign' ? "arrow arrow-up" : "arrow arrow-down"}>Campaign</span>
                </TableCell>
                <TableCell align="left" onClick={()=>handleSort('market')}>
                  <span className={sortOrder.sort === "asc" && sortOrder.column === 'market' ? "arrow arrow-up" : "arrow arrow-down"}>Market </span>
                </TableCell>
                <TableCell align="left" onClick={()=>handleSort('region')}>
                  <span className={sortOrder.sort === "asc" && sortOrder.column === 'region' ? "arrow arrow-up" : "arrow arrow-down"}>Region</span>
                </TableCell>
                <TableCell align="left" onClick={()=>handleSort('format')}>
                  <span className={sortOrder.sort === "asc" && sortOrder.column === 'format' ? "arrow arrow-up" : "arrow arrow-down"}>Format</span>
                </TableCell>
                {/* <TableCell align="left">Views</TableCell> */}
                <TableCell align="left"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageRows?.map((creative, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <RemoveRedEyeIcon sx={{cursor: 'pointer'}} onClick={() => sendDataToModal(creative)} style={{color: "#00ffb4"}}/>
                  </TableCell>
                  <TableCell align="left">{creative.date}</TableCell>
                  <TableCell align="left">{creative.advertiser}</TableCell>
                  <TableCell align="left">{creative.campaign}</TableCell>
                  <TableCell align="left">{creative.market}</TableCell>
                  <TableCell align="left">{creative.region}</TableCell>
                  <TableCell align="left">{creative.format}</TableCell>
                  {/* <TableCell align="left">{creative.views}</TableCell> */}
                  <TableCell align="center"><a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creative?.id}`} onClick={(e) => {
                                    e.preventDefault();
                                    handleLiveDemoClick(creative.id);
                                  }} ><Button sx={{padding: '3px 16px'}} variant="contained" className="btn-livedemo">Live Demo</Button></a></TableCell>

                  {

                    withCRUDOperations ? 
                      <TableCell sx={{display: 'flex' , alignItems: 'center'}} align="left">
                        <IconButton onClick={()=>duplicateCreative(creative)} aria-label="duplicate" size="small">
                          <ContentCopyIcon fontSize="inherit" color="white" />
                        </IconButton>
                        <Link style={{padding: '4px' , display: 'flex'}} to={`/edit-preview/${creative.id}`} target="_blank" rel="noopener noreferrer">
                          <EditIcon fontSize="small" color="white" />
                        </Link>
                        {/* <IconButton onClick={ ()=> {navigate(`/edit-preview/${creative.id}`) }} aria-label="edit" size="small">
                          <EditIcon fontSize="inherit" color="white" />
                        </IconButton> */}
                        <IconButton onClick={ ()=>{ setCurrentCreative(creative); setOpenModalDelete(true)} } aria-label="delete" size="small">
                          <DeleteIcon  fontSize="inherit" color="white" />
                        </IconButton>
                      </TableCell>
                    : null
                  }
                 
                </TableRow>

              ))}
            </TableBody>

          </Table>
            <Pagination className="pagination-table" count={pageCount} page={page + 1} onChange={handlePageChange} />
        </TableContainer>

        <ModalFilter 
          filteredRows={filteredRows}
          resetFilter={resetFilter}
          setFilteredRows={setFilteredRows}
          openModal={modalFilter}
          closeModal={setModalFilter}
          addParamsUrl={addParamsUrl}
          initialFilter={initialFilter}
          setFilterData={setFilterData}
          filterData={filterData}
          applyFilter={applyFilter}
          handleDate={handleDate}
        />
        
      </>
      );
}


export default TableAllCreatives;