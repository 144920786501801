const PreviewServices = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/creatives/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};

const UpdatePreview = async (dataPreview, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/creatives/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: dataPreview.user_id,
        advertiser: dataPreview.advertiser,
        market: dataPreview.market,
        campaign: dataPreview.campaign,
        format: dataPreview.format,
        region: dataPreview.region,
        mode: "show",
        date: new Date().toDateString(),
        url: dataPreview.sizes[0].url,
        sizes: dataPreview.sizes,
      }),
    }
  );
  const data = await response.json();
  console.log("DATA...", data)
  return data;
};

const SaveOnePreview = async (dataPreview, token = "testtotoken") => {
  console.log(token);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/creatives/store`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: dataPreview.user_id,
        advertiser: dataPreview.advertiser,
        market: dataPreview.market,
        campaign: dataPreview.campaign,
        format: dataPreview.format,
        region: dataPreview.region,
        mode: "show",
        date: new Date().toDateString(),
        url: dataPreview.sizes[0].url,
        sizes: dataPreview.sizes,
      }),
    }
  );
  const data = await response.json();
  return data;
};

const ShowAllCreatives = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/creatives/all`
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getAllCrativesCreatedByUser = async (userID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/creatives/user/${userID}`
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const deletePreview = async (idPreview) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/creatives/delete/${idPreview}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};
export {
  PreviewServices,
  SaveOnePreview,
  ShowAllCreatives,
  UpdatePreview,
  getAllCrativesCreatedByUser,
  deletePreview,
};
