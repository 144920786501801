import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary:{
            main: '#0080ff',
            dark: '#191D28',
            light: '#EDEDED',
            contrastText: '#FFFFFF'
        },
        secondary:{
            main: '#0a2756'
        },
        info:{
            main: '#00ffb4'
        },
        dark:{
            main: '#191D28'
        },
        white:{
            main: '#EDEDED'
        }
    },
    typography: {
        fontFamily: [
          'Poppins',
          'sans-serif'
        ].join(','),
    }
})

export default theme;