import {useState , useEffect} from 'react';
import { Modal , Box , Button , TextField , IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { COUNTRIES , FORMATS , REGIONS } from '../../utils/constants'; 
import { useNavigate } from 'react-router-dom'; 
import { useSearchParams } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AutocompleteCustom from './AutocompleteCustom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';

import './Partials.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1300,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

const ModalFilter = ({ openModal , closeModal , filteredRows , resetFilter , addParamsUrl , setFilterData , filterData , applyFilter , handleDate }) => {

  const [stateAllCreatives , setAllCreatives] = useState(filteredRows)

  const handleClose = () => {
    closeModal(false)
  };

  const handleApplyFilters = ( e ) => {
    e.preventDefault()
    addParamsUrl(filterData)
    applyFilter()
    handleClose()
  }

  const resetFilters = ( e ) => {
    e.preventDefault()
    resetFilter()
  }

  return (
    <>
      <Modal
          className="modal-filter"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
              <Button className="btn-close" onClick={handleClose}><CloseIcon sx={{color: '#fff'}}/></Button>
              <form className="modal-filter__form">
                <Box className="container">

                    <Box className="field dates">
                        <label>Select Date Range</label>
                        <Box className="dates">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dayjs(filterData.start_date)}
                              onChange={(e) => handleDate(e , 'start_date' , true)}
                              slots={{
                                openPickerIcon: CalendarMonthIcon
                              }}
                            />
                          </LocalizationProvider>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                value={dayjs(filterData.end_date)}
                                onChange={(e) => handleDate(e , 'end_date' , true)}
                                slots={{
                                    openPickerIcon: CalendarMonthIcon
                                }}
                              />
                          </LocalizationProvider>
                        </Box>
                    </Box>

                    <Box className="field">
                      <label>Advertiser</label>
                        <AutocompleteCustom data={stateAllCreatives} filterData={filterData} field="advertiser" handleUpdate={setFilterData} />
                    </Box>

                    <Box className="field">
                        <label>Campaign</label>
                        <AutocompleteCustom data={stateAllCreatives} filterData={filterData} handleUpdate={setFilterData} />
                    </Box>

                    <Box className="field">
                        <label>Market</label>
                        <AutocompleteCustom data={COUNTRIES} freeSolo={false} direct={true} field="market" filterData={filterData} handleUpdate={setFilterData} />
                    </Box>

                    <Box className="field">
                        <label>Format</label>
                        <AutocompleteCustom data={FORMATS} freeSolo={false} direct={true} field="format" filterData={filterData} handleUpdate={setFilterData} />
                    </Box>

                    <Box className="field">
                        <label>Region</label>
                        <AutocompleteCustom data={REGIONS} freeSolo={false} direct={true} field="region" filterData={filterData} handleUpdate={setFilterData} />
                    </Box>

                </Box>

                <Box className="buttons">
                    <button className="reset" onClick={resetFilters}>Reset all filters</button>
                    <Box>
                      <button onClick={handleClose}>Cancel</button>
                      <button className="apply" onClick={handleApplyFilters}>Apply Filters</button>
                    </Box>
                </Box>

              </form>
          </Box>
      </Modal>
    </>
  );
}

export default ModalFilter;