import * as React from 'react';
import { Modal , Box , Button } from '@mui/material'
import { useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const ModalDelete = ({openModal = false , closeModal , onClickDelete , infoCreative}) => {
  
  const theme = useTheme();

  const handleClose = () => {
    closeModal()
  };

  const handleDelete = () => {
    onClickDelete()
    closeModal()
  }

  return (
    <React.Fragment>
      <Modal
          className="modal-creative"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={{width: '1500px', height: '800px',borderRadius: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflow: 'hidden'}}>
              <Button className="btn-close" onClick={handleClose}><CloseIcon sx={{color: '#fff'}}/></Button>
              <iframe allow="camera *; gyroscope *; accelerometer *; magnetometer *;" style={{width: '1500px', height: '800px', border: 'none'}} src={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview-historial/${infoCreative?.id}`}></iframe>
              <Box className="modal-creative__warning">
                <p className='warning'>Are you sure you want to delete this creative? | <strong> This action cannot be undone</strong></p>
              </Box>
              <Box className="modal-creative__info">
                  <Box className="modal-creative__info-main">
                      <p className="advertiser">{infoCreative?.advertiser}</p>
                      <p className="campaign">{infoCreative?.campaign}</p>
                      <p className="market">{infoCreative?.market}</p>
                  </Box>
                  <Box sx={{position: 'absolute', right: '165px'}}>
                  <Button className="btn-delete" variant="contained" sx={{marginRight: '20px'}} onClick={handleDelete}>
                      Delete
                  </Button>
                  </Box>
              </Box>
          </Box>
      </Modal>
    </React.Fragment>
  );
}

export default ModalDelete;