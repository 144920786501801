import React, {useState, useContext, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom"; 
import { PreviewServices } from "../../services/preview.services";
import DarkModeContext from "../../contexts/DarkModeContext"
import IframePreview from "../../components/IframePreview/IframePreview"
import './IframeCreatives.scss'


const IframeCreatives = () => {

    const [preview, setPreview] = useState({})
    let navigate = useNavigate();
    const {darkMode, setDarkMode} = useContext(DarkModeContext)
    console.log("darkMode-?" , darkMode)
    const {id} = useParams()
    //console.log(useParams())
    
    useEffect(()=>{
        PreviewServices(id)
        .then(data => {
            console.log(data[0]);
            data[0].sizes.forEach(sizeShow => {
                sizeShow.show = true
            });    
            setPreview(data[0])
        })
        .catch(err =>  {console.log(err);
            navigate("/preview-maker", { replace: true })}

   )} , [])


return (
    <>
        <div className={`IframeCreatives  ${ darkMode ? 'darkMode' : '' }`}>
            {
            preview.sizes ? preview.sizes.map((size, index) => <IframePreview key={index} info={size} show={size.show} />) : null
            } 
        </div>
    </>
)
}

export default IframeCreatives