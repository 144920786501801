import React, {useState, useContext, useEffect} from "react";
import IframePreview from "../../components/IframePreview/IframePreview"
import "./ListAllCreatives.scss"
import DarkModeContext from "../../contexts/DarkModeContext"
import LogoNexus from "./images/nexuslogo.svg"
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import AlertSnackbar from "../../components/Partials/AlertSnackbar";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from 'prop-types';
import { ShowAllCreatives , deletePreview , SaveOnePreview } from "../../services/preview.services";
import TableAllCreatives from "../../components/TableAllCreatives/TableAllCreatives";
import {  useNavigate , Navigate  , useSearchParams} from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import SpeedDialMenu from "../../components/SpeedDialMenu/SpeedDialMenu";
import ShareIcon from '@mui/icons-material/Share';
import moment from 'moment';
import ModalDelete from "../../components/Partials/ModalDelete";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { EMAILS } from "../../utils/constants";
import {Backdrop , CircularProgress} from "@mui/material";

const ListAllCreatives = ({ withCRUDOperations = false }) => {
    
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [sizes, setSizes] = useState([])
    const [checkbox, setCheckboxs] = useState(false)
    const [openModalDelete , setOpenModalDelete] = useState(false);
    const {darkMode, setDarkMode} = useContext(DarkModeContext)
    const [creatives, setCreatives] = useState({});
    const [infoCreative, setInfoCreative] = useState()
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [currentCreative , setCurrentCreative] = useState({});

    const [openAlert , setOpenAlert] = useState({
      message: '',
      withProgress: false,
      open: false,
      autoClose: false
    })

    if( searchParams.get('user') ){
      if( EMAILS.includes( searchParams.get('user') ) ){
        withCRUDOperations=true
      }
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    useEffect(()=>{
      if(openAlert.autoClose){
        setTimeout(()=>{
            setOpenAlert({...openAlert , open: false})
        } , 1500)
      }
    },[openAlert.autoClose])

    useEffect(() => {
        fetchCreatives();
    }, []);

    const fetchCreatives = async () => {
      const data = await ShowAllCreatives();
      setCreatives(data);
    };

    const creativesSlider = creatives.creativesSlider;

    console.log("CREATIVES SLIDER ", creatives)

    useEffect(() => {
      const interval = setInterval(() => {
        setValue((prevValue) => (prevValue + 1) % creativesSlider?.length);
      }, 111000); 
  
      return () => clearInterval(interval);
    }, [creativesSlider?.length]);
      

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="panel"
          >
            {value === index && (
              <Box sx={{ p: 2 }} className="into-tab">
                {children}
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };

    const changeDarkMode = () => {
        setDarkMode(!darkMode)
    }
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const getDataofCreativeTable = (data) => {
        setInfoCreative(data)
        setOpen(true)
    }
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            console.log('Texto copiado al portapapeles:', text);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error('Error al copiar al portapapeles:', error);
          });
    };

      
    const handleLiveDemoClick = (creativeId) => {
      const url = `${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creativeId}`;
      window.open(url, '_blank');
    };

    const removeCreative = () =>{
      setOpenAlert({ open: true, message: 'Removing creative', withProgress: true, autoClose: false})
      deletePreview(currentCreative.id).then(data =>{
        setOpenAlert({
          open: true,
          message: 'Creative removed!',
          withProgress: false,
          autoClose: true
        })
        fetchCreatives()
      });
     
    }

    const duplicateCreative = ( creative ) => {
      const {  id , updated_at , created_at , date , ...rest } = creative
      handleClose()
      setOpenAlert({open: true,message: 'Duplicating creative...',withProgress: true,autoClose: false})
      SaveOnePreview({
        ...rest ,
        user_id: 3,
        views: 0,
        advertiser: rest.advertiser + ' - copy',
      }).then(data =>{ 
        setOpenAlert({ open: true, message: 'Creative duplicated!', withProgress: false, autoClose: true })
        fetchCreatives()
      })
    }

    if(Object.keys(creatives).length <= 0){
      return(
        <Backdrop
          sx={{ color: "#fff" , backgroundColor: '#191D28', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )
    }else{
      return (
          <>
              {
                withCRUDOperations ? 
                <div className="ListAllCreativesPage__warning">
                  Warning! You are in editing mode. Once you delete a creative, you can't undo it.
                </div> : null
              }
              <div className={darkMode ? "ListAllCreativesPage darkmode" : "ListAllCreativesPage"}  >
                  <div className={`ListAllCreativesPage__header ${ withCRUDOperations ? 'warning' : '' }`}>
                      <div>
                          <img src={LogoNexus} alt="Logo GroupM Nexus"/>
                          <span className="line-separator"></span>
                          <p>Global Creative Database</p>
                      </div>
                      <div className="counter-creatives">
                          <div className="number">
                            {
                              creatives.allCreatives ? (creatives.allCreatives.length / 1000).toFixed(3) :  '-'
                            }
                          </div>
                          <div className="text">
                            Available Creatives
                          </div>
                      </div>
                  </div>
                  <div className="ListAllCreativesPage__hightlights">
                      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
                          <Tabs className="tabs" orientation="vertical" variant="scrollable" value={value} onChange={handleChange} aria-label="Vertical tabs example" sx={{ borderRight: 0, borderColor: 'divider' }}>
                              {creativesSlider?.map((creative) => (
                                  <Tab className="holi" key={creative.id} label={<Typography>{creative.advertiser}</Typography>}/>
                              ))}
  
                          </Tabs>
                          {creativesSlider?.map((creative, index) => (
                              <TabPanel value={value} index={index} key={index}>
                              <div className="info-preview">
                                  <div className="info">
                                      <p className="campaign">{creative.campaign}</p>
                                      <span className="date">{moment(creative.date).format('DD MMMM YYYY')}</span>
                                      {/* <p className="advertiser">{creative.advertiser}</p> */}
                                      <p className="market">{creative.market}</p>
                                      <p className="format">{creative.format}</p>
                                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creative.id}`} onClick={(e) => {
                                      e.preventDefault();
                                      handleLiveDemoClick(creative.id);
                                    }} ><Button variant="contained" className="btn-livedemo">Live Demo</Button></a>
                                  </div>
                              
                              </div>
                              <div className={`iframe-creatives  ${ darkMode ? 'darkMode' : '' }`}>
                                  <iframe allow="camera *; gyroscope *; accelerometer *; magnetometer *;" src={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview-historial/${creative.id}`}></iframe>
                                  <div className="background-iframe-overlay"></div>
                              </div>
                          </TabPanel>
                          ))}
                      </Box>
                  </div>
                  <div className="listAllCreativesPage__table">
                    <TableAllCreatives 
                      withCRUDOperations={withCRUDOperations} 
                      openIframeCreative={getDataofCreativeTable}
                      creatives={creatives}
                      removeCreative={removeCreative}
                      duplicateCreative={duplicateCreative}
                      setCurrentCreative={setCurrentCreative}
                      setOpenModalDelete={setOpenModalDelete}
                    />
                  </div>
              </div>
  
              <ModalDelete
                infoCreative={currentCreative}
                openModal={openModalDelete}
                closeModal={setOpenModalDelete}
                onClickDelete={removeCreative}
              />
  
              <Modal
                  className="modal-creative"
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  >
                  <Box sx={{width: '1500px', height: '800px', borderRadius: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflow: 'hidden'}}>
                      <Button className="btn-close" onClick={handleClose}><CloseIcon sx={{color: '#fff'}}/></Button>
                      {/* <Box className="modal-creative__container-iframe"> */}
                        <iframe allow="camera *; gyroscope *; accelerometer *; magnetometer *;" style={{width: '1500px', height: '800px' , border: 'none'}} src={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview-historial/${infoCreative?.id}`}></iframe>
                      {/* </Box> */}
                      <Box className="modal-creative__info">
                          <Box className="modal-creative__info-main">
                              <span className="date">{infoCreative?.date}</span>
                              <p className="advertiser">{infoCreative?.advertiser}</p>
                              <p className="campaign">{infoCreative?.campaign}</p>
                              <p className="market">{infoCreative?.market}</p>
                          </Box>
                          <Box sx={{position: 'absolute', right: '165px' , display: 'flex' , alignItems:'center'}}>
                            <Button variant="contained" sx={{marginRight: '20px'}} startIcon={<ShareIcon />} onClick={() => copyToClipboard(`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${infoCreative?.id}`)}>
                                Share
                            </Button>
                              <a   target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${infoCreative?.id}`} onClick={(e) => {
                                      e.preventDefault();
                                      handleLiveDemoClick(infoCreative?.id);
                                    }}  ><Button variant="contained" className="btn-livedemo">Live Demo</Button></a>
  
                              { 
                                withCRUDOperations ? 
                                  <Box sx={{marginLeft:'8px'}}>
                                    <IconButton sx={{padding:'2px'}} onClick={()=>duplicateCreative(infoCreative)} aria-label="duplicate" size="small">
                                      <ContentCopyIcon fontSize="inherit" color="white" />
                                    </IconButton>
                                    <IconButton sx={{padding:'2px'}} onClick={ ()=> {navigate(`/edit-preview/${infoCreative.id}`) }} aria-label="edit" size="small">
                                      <EditIcon fontSize="inherit" color="white" />
                                    </IconButton>
                                    <IconButton sx={{padding:'2px'}} onClick={ ()=>{ setCurrentCreative(infoCreative); handleClose(); setOpenModalDelete(true)} } aria-label="delete" size="small">
                                      <DeleteIcon  fontSize="inherit" color="white" />
                                    </IconButton>
                                  </Box>
                                    : null
                              }
                          </Box>
                          <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                              <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                  Link copied to clipboard
                              </Alert>
                          </Snackbar>
                      </Box>
                  </Box>
              </Modal>
              {
                openAlert.open ? 
                  <AlertSnackbar 
                    openAlert={openAlert.open} 
                    autoClose={openAlert.autoClose} 
                    message={openAlert.message} 
                    withProgress={openAlert.withProgress} 
                    closeAlert={setOpenAlert} 
                  /> : null
              } 
              <SpeedDialMenu/>
          </>
      )

    }

}

export default ListAllCreatives;