import React, {useEffect, useState, useContext} from "react";
import {useParams, useNavigate} from "react-router-dom"; 
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ConstructionIcon from '@mui/icons-material/Construction';
import TableChartIcon from '@mui/icons-material/TableChart';


const SpeedDialMenu = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
      };
    const actions = [
        { icon: <DashboardIcon onClick={()=>{navigate("/preview-maker/dashboard")}} />, name: 'Dashboard' },
        { icon: <ConstructionIcon onClick={()=>(navigate("/preview-maker"))} />, name: 'Preview Maker' },
        { icon: <TableChartIcon onClick={()=>(navigate("/global-creative-database"))} />, name: 'Creative history' },
      ];
    return(
        <>
            <SpeedDial ariaLabel="Menu between pages" open={open} onClick={handleClick} sx={{ position: 'fixed', bottom: 20, right: 20 }} icon={<SpeedDialIcon />}>
                {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                />
                ))}
            </SpeedDial>
        </>
    )
}



export default SpeedDialMenu