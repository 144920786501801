const LoginServices = async(submitData) => {
    const responseLogin = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
    })
    const data = await responseLogin.json()
    return data
}

export {LoginServices}
