import React from 'react'
import {Autocomplete , TextField} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const AutocompleteCustom = ({data , field='campaign' , direct=false , filterData , handleUpdate , freeSolo=true}) => {

    const dataSet = new Set([])

    let autocompleteData = []

    if(direct){
        autocompleteData = data
    }else{
        
        data.forEach((data) => {
            dataSet.add(data[field])
        })
    
        autocompleteData = Array.from(dataSet)
    }


    const handleAutocomplete = (e , value) =>{
        handleUpdate({
            ...filterData,
            [field] : value === null ? '' : value
        })
    }

    return (
        <Autocomplete
          disablePortal
          freeSolo={freeSolo}
          options={autocompleteData}
          value={filterData[field]}
          popupIcon={<KeyboardArrowDownIcon color="secondary" />}
          onChange={handleAutocomplete}
          sx={[
            {
                '&' : {width: 170}
            },
            {
                '.MuiAutocomplete-inputRoot' : {
                    padding: '0 !important'
                }
            }
          ]}
          renderInput={(params) => <TextField {...params}  />}
        />
    );
}

export default AutocompleteCustom