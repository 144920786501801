import React, {useState, useContext , useRef , useEffect} from "react";
import { useParams , useNavigate } from "react-router-dom"; 
import FormPreview from "../../components/FormPreview";
import IframePreview from "../../components/IframePreview/IframePreview"
import "./editPreviewPage.scss"
import DarkModeContext from "../../contexts/DarkModeContext"
import SpeedDialMenu from "../../components/SpeedDialMenu/SpeedDialMenu";
import { Helmet } from 'react-helmet';
import xcs_logo from "../images/xcs_logo.svg"
import { PreviewServices } from "../../services/preview.services";
import {Backdrop , CircularProgress} from "@mui/material";

const EditPreviewPage = () => {

    const switchButtonDarkMode = useRef(false)
    const [sizes, setSizes] = useState([])
    const [checkbox, setCheckboxs] = useState(false)
    const {darkMode, setDarkMode} = useContext(DarkModeContext)
    const [dataPreview, setDataPreview] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { id } = useParams()
    const navigate = useNavigate()
   
    useEffect(()=>{
        if( darkMode  && switchButtonDarkMode.current ){
            switchButtonDarkMode.current.checked = true;
        }
    },[switchButtonDarkMode.current])

    useEffect(() => {
      setIsLoading(true)
      PreviewServices(id)
        .then((data) => {
          if(Object.keys(data).length === 0) {
            navigate('preview-maker')
          } else {
            data[0].sizes.forEach((sizeShow) => {
              sizeShow.show = true;
            });
            setDataPreview(data[0]);
            setIsLoading(false)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    const changeDarkMode = () => {
        setDarkMode(!darkMode)
        localStorage.setItem('isDarkMode' , !darkMode)
    }

    return (
        <>
            <Helmet>
                {/* Meta tags para Open Graph */}
                <meta property="og:title" content="Edit Preview Builder" />
                <meta property="og:description" content="Edit Preview creatives built by XCS" />
                <meta property="og:image" content={xcs_logo} />

                {/* Meta tags para Twitter Card */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Edit Preview Builder" />
                <meta name="twitter:description" content="Edit Preview creatives built by XCS" />

                <meta name="description" content="Edit Preview creatives built by XCS" />
                <title>Edit Preview Builder</title>
            </Helmet>
            {isLoading ? (
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
              <>
                <div className={darkMode ? "previewpage__body darkmode" : "previewpage__body"}  >
                    <FormPreview  setSizes={setSizes} sizes={sizes} mode="edit" setCheckboxs={setCheckboxs} dataPreview={ dataPreview } checkbox={checkbox}/>
                    <div className="previewpage__body-iframes">
                        <div className="darkmode">
                            <div className="c-toggle">
                            <label className="switch">
                                <input id="checkboxCreatives" ref={switchButtonDarkMode} onChange={changeDarkMode} type="checkbox"  data-toggle="true"/>
                                <span className="slider round"></span>
                                <span className="text text-on">Dark Mode On</span>
                                <span className="text text-off">Dark Mode Off</span>
                            </label>
                        </div>
                        </div>
                        { 
                            sizes.map((size, index) => <IframePreview key={index} info={size} check={checkbox} /> )
                        }
                    </div>

                    
                </div>
                <SpeedDialMenu/>
              </>
          )}
        </>
    )
}

export default EditPreviewPage;