import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

export const AlertSnackbar = ({message , openAlert , autoClose, withProgress = true}) => {

    const handleClose = () => {
        // closeAlert();
    }
    
    return (
        <Snackbar
            open={openAlert}
            onClose={handleClose}
            TransitionComponent={Slide}
            message={message}
            anchorOrigin={{vertical: 'top' , horizontal:'right'}}
            autoHideDuration={autoClose ? 2000 : null}
            action={ withProgress ? <CircularProgress color="white" size={20} /> : null }
        />
    )
}


export default AlertSnackbar;