import { useState } from 'react'
import logonexus from "../../components/images/nexuslogo.svg"


const HeaderMobile = ({preview}) => {
  
  const [ isOpen , setIsOpen ] =  useState(false)

  const handleOpen = () =>{
    setIsOpen(!isOpen)
  }
 
  return (
    <div className="showpreview__sidebar">
        <div>
            <div className='showpreview__sidebar-intro'>
                <img src={logonexus} alt='logo'/>   
            </div>
            <h1>Creatives Preview</h1>
            {
                isOpen ? 
                    <div className="showpreview__sidebar-body">
                        <div className="show-advertiser">
                            {preview.advertiser}
                        </div>
                        <div className="market">
                            {preview.market}
                        </div>
                        <div className="campaign">
                            {preview.campaign}
                        </div>
                    </div>
                : null
            }
            
            <div className='open-title' onClick={handleOpen}> <span className={isOpen ? 'open' : 'close'}>{ isOpen ? 'HIDE' : 'MORE INFO' }</span></div>
        </div>
    </div>
  )
}

export default HeaderMobile;