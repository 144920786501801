 const ValidationForm = (values) => {
        
            console.log(values);
        const {advertiser} = values
        console.log(typeof advertiser);
        let errors = {}

        if (values.advertiser === "") {
            //errors.advertiser = "El campo de ADVERTISER es necesario"
            errors = {...errors, advertiser: "Complete this input"}
        }
        if (values.market === "") {
            errors = {...errors, market: "Complete this input"}
        }
        if (values.campaign === "") {
            errors = {...errors, campaign: "Complete this input"}
        }
        if (values.format === "") {
            errors = {...errors, format: "Complete this input"}
        }
        if (values.region === "") {
            errors = {...errors, region: "Complete this input"}
        }
        values.sizes.forEach(element => {
            if(element.url === "") {
                errors = {...errors, sizes: "Complete this input"}
            }
        });

        console.log(errors);
        return errors
}

const  ValidationFormLogin = (values) => {
    let errors = {}

    if (values.email === "") {
        errors = {...errors, email: "Complete this input"}
    }
    if (values.password === "") {
        errors = {...errors, password: "Complete this input"}
    }
    console.log(errors);
    return errors
}
export  {ValidationForm, ValidationFormLogin}
