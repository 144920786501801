import CreatePreviewPage from './pages/createPreviewPage/createPreviewPage'
import EditPreviewPage from './pages/editPreviewPage/editPreviewPage';
import ShowPreview from './pages/ShowPreview/ShowPreview';
import LoginPage from './pages/LoginPage/LoginPage';
//import AuthContext from './contexts/AuthContext';
import DarkModeContext from './contexts/DarkModeContext';
import ListAllCreatives from './pages/ListAllCreatives/ListAllCreatives';
import IframeCreatives from './components/IframeCreatives/IframeCreatives';
import Dashboard from './pages/Dashboard/Dashboard';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/theme'; 
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Redirect
} from "react-router-dom"; 
import { useState } from 'react';
import Register from './pages/Register/Register';
import { PrivateRoutes } from './helpers/helpers';

function App() {
  const [token, setToken] = useState()

  const darkModeStorage = localStorage.getItem('isDarkMode') ? ( localStorage.getItem('isDarkMode') == 'true' ? true : false ) : false;
  const [darkMode, setDarkMode] = useState(darkModeStorage)
  const isAuthenticate = false
  return (
    <>
        {/* <AuthContext.Provider value={{token, setToken}}>
        </AuthContext.Provider> */}
        <ThemeProvider theme={theme}>
          <DarkModeContext.Provider value={{darkMode, setDarkMode}}>
              <Routes>
                <Route element={<PrivateRoutes/>}>
                  <Route path="/login" element={<LoginPage/>} />
                  <Route path="/preview-maker" element={<CreatePreviewPage/>} />
                  <Route path="/edit-preview/:id" element={<EditPreviewPage/>} />
                  <Route path="/preview-maker/dashboard" element={<Dashboard/>}/>
                  <Route path="/show-preview-historial/:id" element={<IframeCreatives/>} /> 
                  <Route path="/global-creative-database" element={<ListAllCreatives/>} />
                </Route>
                  <Route path="/show-preview/:id" element={<ShowPreview/>} />
                <Route path='/register' element={<Register/>}/> 
              </Routes>
            </DarkModeContext.Provider>
        </ThemeProvider>

    </>
  );
}

export default App;
