import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ShowAllCreatives } from "../../services/preview.services";
import Button from "@mui/material/Button";
import "./../TableAllCreatives/TableAllCreatives.scss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Pagination } from "@mui/material";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "./../TableAllCreatives/images/search-icon.svg";
import FilterIcon from "./../TableAllCreatives/images/filter-icon.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModalDeletePreview from "../ModalDeletePreview/ModalDeletePreview";

const TableCreativesByUser = (props) => {
  const [allCreatives, setAllCreatives] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [openModal, setOpenModal] = useState(false)
  const [idPreview, setIdPreview] = useState(false)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { creativesUser } = props;

  // console.log("PROPS", props);

  // Definir el estado para la página actual
  const [page, setPage] = useState(0);
  // Definir la cantidad de filas por página
  const rowsPerPage = 10;

  const navigate = useNavigate();

  //    const allCreatives = creatives;

  useEffect(() => {
    //console.log(typeof creativesUser);
    if (creativesUser !== undefined) {
      setAllCreatives(creativesUser.creatives);
    }
  }, [creativesUser, openModal]);

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  // Filtra los resultados según el término de búsqueda
  const filteredRows = allCreatives
    ?.filter((creative) => {
      return (
        creative.advertiser.toLowerCase().includes(searchTerm.toLowerCase()) ||
        creative.campaign.toLowerCase().includes(searchTerm.toLowerCase()) ||
        creative.market.toLowerCase().includes(searchTerm.toLowerCase()) ||
        creative.region.toLowerCase().includes(searchTerm.toLowerCase()) ||
        creative.format.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .sort((a, b) => {
      const dateA = new Date(a.date).toISOString();
      const dateB = new Date(b.date).toISOString();
      return sortOrder === "asc"
        ? dateA.localeCompare(dateB)
        : dateB.localeCompare(dateA);
    });

  // Calcular la cantidad de páginas en función de la cantidad total de filas
  const pageCount = Math.ceil(filteredRows?.length / rowsPerPage);

  // Definir el índice de la primera y última fila en función de la página actual y la cantidad de filas por página
  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, allCreatives?.length);

  // Obtener solo las filas que deben mostrarse en la página actual
  const pageRows = filteredRows?.slice(startIndex, endIndex);

  // Manejar el cambio de página
  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const sendDataToModal = (creative) => {
    props.openIframeCreative(creative);
  };
  const handleLiveDemoClick = (creativeId) => {
    const url = `${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creativeId}`;
    window.open(url, "_blank");
  };
  const handleEditeCreative = (creativeId) => {
    const url = `${process.env.REACT_APP_PREVIEW_IFRAME}/edit-preview/${creativeId}`;
    window.open(url, "_blank");
  };

  const handleOpenModalDeletePreview = (idPreview) => {
    setOpenModal(true)
    setIdPreview(idPreview)
  }
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Box className="Filter">
        <Box className="Filter__search">
          <img src={SearchIcon} alt="search icon"></img>
          <TextField
            className="Filter__search-textField"
            label="Search"
            variant="filled"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button>
            <img src={FilterIcon} alt="filter icon"></img>Filter
          </Button>
        </Box>
      </Box>
      <TableContainer
        sx={{ minHeight: 640 }}
        className="TableAllCreatives"
        component={Paper}
      >
        <Table aria-label="sticky table" stickyHeader>
          <TableHead>
            <TableRow>
              {/* <TableCell align="left">Preview</TableCell>*/}
              <TableCell
                sx={{ cursor: "pointer" }}
                align="left"
                onClick={handleSort}
              >
                Date {sortOrder === "asc" ? "↑" : "↓"}
              </TableCell>
              <TableCell align="left">Advertiser</TableCell>
              <TableCell align="left" style={{ width: "200px" }}>
                Campaign
              </TableCell>
              <TableCell align="left">Market</TableCell>
              <TableCell align="left">Region</TableCell>
              <TableCell align="left">Format</TableCell>
              {/* <TableCell align="left">Views</TableCell> */}
              <TableCell align="left"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageRows?.map((creative, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/*        <TableCell align="center" component="th" scope="row">
                    <RemoveRedEyeIcon sx={{cursor: 'pointer'}} onClick={() => sendDataToModal(creative)} style={{color: "#d4f665"}}/>
                  </TableCell> */}
                <TableCell align="left">{creative.date}</TableCell>
                <TableCell align="left">{creative.advertiser}</TableCell>
                <TableCell align="left">{creative.campaign}</TableCell>
                <TableCell align="left">{creative.market}</TableCell>
                <TableCell align="left">{creative.region}</TableCell>
                <TableCell align="left">{creative.format}</TableCell>
                {/* <TableCell align="left">{creative.views}</TableCell> */}
                <TableCell align="center" sx={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', 'a': {margin: '0 10px'}}}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creative?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLiveDemoClick(creative.id);
                    }}
                  >
                    <LaunchIcon sx={{ color: "white" }} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creative?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenModalDeletePreview(creative.id);
                    }}
                  >
                    <DeleteIcon sx={{ color: "white" }} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${creative?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditeCreative(creative.id);
                    }}
                  >
                    <ModeEditIcon sx={{ color: "white" }} />
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          className="pagination-table"
          count={pageCount}
          page={page + 1}
          onChange={handlePageChange}
        />
      </TableContainer>
      <ModalDeletePreview idPreview={idPreview} openModal={openModal} closeModal={handleCloseModal}/>
    </>
  );
};

export default TableCreativesByUser;
