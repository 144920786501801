import React, {useState, useContext , useRef , useEffect} from "react";
import FormPreview from "../../components/FormPreview";
import IframePreview from "../../components/IframePreview/IframePreview"
import "./createPreviewPage.scss"
import DarkModeContext from "../../contexts/DarkModeContext"
import SpeedDialMenu from "../../components/SpeedDialMenu/SpeedDialMenu";
import { Helmet } from 'react-helmet';
import xcs_logo from "../images/xcs_logo.svg"



const CreatePreviewPage = () => {

    const switchButtonDarkMode = useRef(false)
    const [sizes, setSizes] = useState([])
    const [checkbox, setCheckboxs] = useState(false)
    const {darkMode, setDarkMode} = useContext(DarkModeContext)

    useEffect(()=>{
        if( darkMode  && switchButtonDarkMode.current ){
            switchButtonDarkMode.current.checked = true;
        }
    },[switchButtonDarkMode.current])

    const changeDarkMode = () => {
        setDarkMode(!darkMode)
        localStorage.setItem('isDarkMode' , !darkMode)
    }
    return (
        <>
            <Helmet>
                {/* Meta tags para Open Graph */}
                <meta property="og:title" content="Preview Builder" />
                <meta property="og:description" content="Preview creatives built by XCS" />
                <meta property="og:image" content={xcs_logo} />

                {/* Meta tags para Twitter Card */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Preview Builder" />
                <meta name="twitter:description" content="Preview creatives built by XCS" />

                <meta name="description" content="Preview creatives built by XCS" />
                <title>Preview Builder</title>
            </Helmet>
            <div className={darkMode ? "previewpage__body darkmode" : "previewpage__body"}  >
                <FormPreview setSizes={setSizes} sizes={sizes} setCheckboxs={setCheckboxs} checkbox={checkbox}/>
                <div className="previewpage__body-iframes">
                    <div className="darkmode">
                        <div className="c-toggle">
                        <label className="switch">
                            <input id="checkboxCreatives" ref={switchButtonDarkMode} onChange={changeDarkMode} type="checkbox"  data-toggle="true"/>
                            <span className="slider round"></span>
                            <span className="text text-on">Dark Mode On</span>
                            <span className="text text-off">Dark Mode Off</span>
                        </label>
                    </div>
                    </div>
                    { 
                        sizes.map((size, index) => <IframePreview key={index} info={size} check={checkbox} /> )
                    }
                </div>

                
            </div>
            <SpeedDialMenu/>
        </>
    )
}

export default CreatePreviewPage;