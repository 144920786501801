import {Outlet, Navigate} from 'react-router-dom'
import React, { useEffect, useState } from 'react';

const PrivateRoutes = () => {
    const [auth, setAuth] = useState(false)


    // Función para establecer una cookie de sesión
    function setCookie(name, value) {
        document.cookie = name + "=" + value + "; path=/";
    }
    

    console.log("cookie" , auth)


    useEffect(() => {
        // Función para obtener el valor de una cookie por su nombre
        function getCookieValue(cookieName) {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
              }
            }
            return null;
          }
    
        // Obtener el valor de la cookie
        const cookieValue = getCookieValue("xcs_login");
        console.log(cookieValue);
    
        // Verificar si la cookie existe
        if (cookieValue) {
          // Realizar una solicitud al backend para validar la cookie
          fetch(`${process.env.REACT_APP_API_URL}/api/cookie-validation`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "token" : cookieValue })
            })
            .then(response => response.json())
            .then(data => {
                console.log("respodne el server", data);
            if (data.auth) {
                console.log(data);
                setAuth(true)
                console.log("La cookie es válida");
            } else {
                setAuth(false) //local is true
              throw new Error("Error al validar la cookie si el auth es false");
            }
          })
          .catch(error => {
            console.error("Error al validar la cookie en el fetch", error);
            if(process.env.REACT_APP_ENVIRONMENT === 'local'){
              setAuth(true)
            }else{
              setAuth(false)
              window.location.href = "https://www.nexus-creative-solutions.com/login/?fromPage=preview-history";
            }
          });
        } else {
          if(process.env.REACT_APP_ENVIRONMENT === 'local'){
            setAuth(true)
          }else{
            // La cookie no existe, redirección a  la pagina para obtener la cookie bajo el dominio
            setAuth(false)
            window.location.href = "https://www.nexus-creative-solutions.com/login/?fromPage=preview-history";
          }
        }
      }, []);


    return(
       auth ? <Outlet/> : null
    )
}

export {PrivateRoutes}