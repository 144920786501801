const FooterMobile = () => {
  
  const dateYear = new Date();
  const anio = dateYear.getFullYear();
 
  return (
    <div className="showpreview__footer-mobile">
        <span>©NEXUS CREATIVE SOLUTIONS {anio} ALL RIGHTS RESERVED</span>
    </div>
  )
}

export default FooterMobile;