import React, {useState, useContext, useEffect, useRef} from "react";
import reload from "./images/reload.png"
import "./IframePreview.scss"
import DarkModeContext from "../../contexts/DarkModeContext";
import { display } from "@mui/system";
import Preloader from './images/preloader.svg'

const IframePreview = ({info, check, show = true}) => {

    const {darkMode} = useContext(DarkModeContext)
    let [index , setIndex]= useState(0)
    const [loading, setLoading] = useState(true);
    const iframeRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        const iframeLoaded = () => {
          if(iframe){
            const videoIframe = iframe.contentWindow.document.querySelector('video');
            if(videoIframe){
              videoIframe.muted = true;
            }
          }
          setLoading(false);
        };
    
        const iframe = iframeRef.current;
        if (iframe) {
          iframe.addEventListener("load", iframeLoaded);
        }
    
        return () => {
          if (iframe) {
            iframe.removeEventListener("load", iframeLoaded);
          }
        };
      }, [index]);



    const checkVerify = check;
    let width = check ?  "100%" : `${info.width}px`
    let height = check ?  "" : `${info.height}px`
    let heightIframe = check ?  "100vh" : `${info.height}px`
    let minHeightIframe = check ?  "calc(100vh - 100px)" : `${info.height}px`
    let showIframe = show ? "block" : "none"
    let styles = {
        width,
        // height,
        maxHeight: minHeightIframe,
        minHeight: minHeightIframe,
        display: showIframe

    }
    let stylesDiv = {
      width,
      minHeight: minHeightIframe,
      display: showIframe
    }


    const checkIfPreviewIsMP4 = (url) => {
      return url.search(/.mp4/) >= 0 ? true : false
    }

    const handleRefreshIframe = () => {

        setIndex(++index)
    }
    const handleIframeRef = (ref) => {
        iframeRef.current = ref;
    };
    return(
        <>
            {
                info.url !== "" ? 
                    <div className={ darkMode ? "card-iframe darkmode" : `card-iframe ${check ? null : "nofullsize"}`  } style={stylesDiv}>
                        {
                          info.banner_text !== '' && info.banner_text !== null ? <span className="bannertext">{info.banner_text}</span> : null
                        }
                        <div className="card-iframe__info"><span className="card-iframe__info-size">{check ? "Full size" : `${info.width}x${info.height}`}</span><span onClick={handleRefreshIframe} className="card-iframe__info-reload"><img alt="Refresh Icon" src={reload}></img></span></div> 
                               {/* <div style={{display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#e8e8e8'} } className="skeleton"><img style={{width: '60px'}} src={Preloader}/></div> */}
                                <div style={{display: loading ? 'block' : 'block' , border: '1px solid #EDEDED' , width: '100%'} }>
                                {
                                  !checkIfPreviewIsMP4(info.url) ?
                                  <iframe 
                                    key={index}
                                    ref={handleIframeRef}
                                    title="iframes"
                                    src={info.url}
                                    style={styles}
                                    allow="camera *; gyroscope *; accelerometer *; magnetometer *;"
                                  ></iframe> : 
                                  <video controls autoPlay={true} muted style={styles}>
                                    <source src={info.url} type="video/mp4" />
                                    Tu navegador no soporta el video.
                                  </video>
                                }
                                </div>

                   
                    </div>
                : null
            }
        </>
    )
}

export default IframePreview;