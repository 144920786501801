export const DEFAULT_SIZES = [ 
    {    
        height: "250",
        url: "",
        width: "300",
        banner_text: ""
    },
    {
        height: "480",
        url: "",
        width: "320",
        banner_text: ""
    },
    {
        height: "600",
        url: "",
        width: "300",
        banner_text: ""
    },
    {
        height: "600",
        url: "",
        width: "160",
        banner_text: ""
    },
    {
        height: "90",
        url: "",
        width: "728",
        banner_text: ""
    }
]

export const DEFAULT_PREVIEW = {
    user_id    : 3,
    advertiser : "",
    banner_text: "",
    market     : "",
    campaign   : "",
    format     : "",
    region     : "",
    width      : "",
    height     : "",
    sizes      : [...DEFAULT_SIZES],
}

export const COUNTRIES = [
        "Argentina",
        "Peru",
        "Mexico",
        "Puerto Rico", 
        "Miami",
        "Colombia",
        "Chile",
        "Uruguay",
        "Ecuador",
        "Brazil",
        "United Kingdom",
        "The Netherlands", 
        "Portugal",
        "Spain",
        "Italy",
        "Germany",
        "Belgium",
        "Switzerland",
        "Finland",
        "Denmark",
        "Norway",
        "Australia",
        "Singapore",
        "Malaysia",
        "China",
        "Hong Kong",
        "Vietnam",
        "Philippines",
        "New Zealand",
        "MENA",
        "XCS",
        "Indonesia",
        "India",
        "Pakistan",
        "Thailand",
        "Taiwan",
        "Korea",
        "Sweden",
        "Canada",
        "United States"
]

export const EMAILS = ['andres.alfonso@groupm.com','carlos.perez@groupm.com', 'danny.matallana@groupm.com', 'andres.galvez@groupm.com', 'alejandra.orjuela@groupm.com', 'laura.bravo@groupm.com', 'laura.vargas@groupm.com', 'juan.prada@groupm.com', 'david.bustamante@groupm.com', 'oscar.nino@groupm.com', 'brandon.forero@groupm.com', 'maria.tamara@groupm.com', 'ruben.torres@groupm.com', 'cesar.velandia@groupm.com', 'juan.hilarion@groupm.com', 'fabio.sanchez@groupm.com', 'camila.espana@groupm.com', 'duvan.angel@groupm.com', 'sandra.gordillo@groupm.com', 'vanessa.gutierrez@groupm.com', 'ana.rico@groupm.com'];

export const FORMATS = [
    "3D Showroom",
    "Standard Banners",
    "Playable Ads",
    "Videoboard",
    "Shoppable",
    "Interstitial",
    "Lookbook",
    "Advanced TV",
    "Interscroller",
    "Reactive",
    "Click to call",
    "Click to calendar",
    "SONA",
    "Tap to Map",
    "Conversational Ads",
    "Prism",
    "Expandable",
    "Skin video",
    "DCO",
    "Billboard",
    "Filmstrip",
    "XCS Format"
]

export const REGIONS = [ 'LATAM' , 'EMEA' , 'APAC' , 'NTAM' , 'GLOBAL' ]