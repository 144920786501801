import React, {useState, useContext, useEffect} from "react"
import { LoginServices } from "../../services/login.services"
import './LoginPage.scss'
import logo from "./images/logo.png"
import AuthContext from "../../contexts/AuthContext"
import { useNavigate } from "react-router-dom"
import {ValidationFormLogin} from "../../services/validationForm.services"

const LoginPage =  () => {


    let navigate = useNavigate()
    const {token, setToken} = useContext(AuthContext)
    const [errors, setErrors] = useState(null)
    const [dataSubmit, setDataSubmit] = useState({
        email : "",
        password : ""
    })

    console.log("llamado desde el login from " + token)
    
    useEffect(() => {
        console.log("use effect", errors);
        if (errors) {
            submitLogin()
        }
    }, [errors] )

    const submitLogin = () => {
        console.log("submit login trae: ", errors);
        let lengthErrors = Object.keys(errors).length
        console.log(lengthErrors);
        if (lengthErrors === 0) {
            LoginServices(dataSubmit)
            .then(data => {
                if (data.error) {
                    console.log(data.errors);
                    setErrors({...data})
                }else {
                    setToken(data.token)
                    navigate("/dashboard", { replace: true })
                }
                console.log(data)
            })
            .catch(err => {
                console.log("esto es un error", err);
            }) 
  
         }
    }

      const handleOnChange = (e) => {
        setDataSubmit({...dataSubmit, [e.target.name]: e.target.value})
      }

      const handleOnSubmit = (e) => {
        e.preventDefault()
        let errorsFormLogin = ValidationFormLogin(dataSubmit)
        setErrors({...errorsFormLogin})

      }

    return (
        <>
            <div className="login" onSubmit={(e) => handleOnSubmit(e)}>
                <div className="login__intro">
                    <div className="login__intro-panel">
                        <div className="content">
                        <img src={logo} className="image" alt="XAXIS"/>
                            <h3>Preview Maker Tool</h3>
                        </div>
                    </div>
                </div>
                <div className="login__form">
                    <div className="login__form-signin">
                        <form action="">
                            <h2 className="title">User Login</h2>
                            <div className="field">
                                <i className="user"></i>
                                <input onChange={(e) => handleOnChange(e)} type="text" name="email" autoComplete="false" id="email" placeholder="Email"/>
                                {
                                    errors?.email ? <span className='errors'>{errors.email}</span> : null
                                 }
                            </div>
                            <div className="field">
                                <i className="password"></i>
                                <input  onChange={(e) => handleOnChange(e)} type="password" name="password" id="password" placeholder="Password"/>
                                {
                                    errors?.password ? <span className='errors'>{errors.password}</span> : null
                                }
                            </div>
                            <div className="fields">
                                <div className="checkbox">
                                    <input type="checkbox" name="remember" id="remember" /><div className="checkmark"></div><label htmlFor="remember">Remember</label>
                                    
                                </div>
                                <div className="forgotpassword">
                                    <a href="/prview-maker">Forgot password?</a>
                                </div>
                            </div>
                                {
                                    errors?.errors ? <span className='errors'>{errors.errors}</span> : null
                                }
                            <input type="submit" value="Login" className="btn"/>
                        </form>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default LoginPage