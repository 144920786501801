import { DEFAULT_PREVIEW  } from "./constants"

export const loadDefaultPreview = () =>{

    if( localStorage.getItem('preview') ){
        return JSON.parse( localStorage.getItem('preview') )
    }else{
        return DEFAULT_PREVIEW
    }

}

export const setPreviewInLocalStorage = ( preview ) =>{

    localStorage.setItem('preview' , JSON.stringify(preview));

}

export const clearPreviewInLocalStorage = (  ) =>{

    localStorage.removeItem('preview')
}

export const sortDataAlphabetically = (  data ) => {
    data.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        } else if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        } else {
          return 0; 
        }
    });

    return data
}