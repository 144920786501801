import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { deletePreview } from "../../services/preview.services";

const ModalDeletePreview = ({ idPreview, openModal, closeModal }) => {
  const [open, setOpen] = useState(false);
  const [isDeletePreview, setIsDeletePreview] = useState(false)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(()=> {
    setOpen(openModal)
  }, [openModal, isDeletePreview])

  const handleDeletePreview = (idPreview) => {
    deletePreview(idPreview)
    .then(data => {
      console.log(data);
      setIsDeletePreview(true)
    })
    .catch(error => {
      console.log(error);
    })
  }

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete preview
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this preview? ID Preview: {idPreview}
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button variant="contained" color="error" onClick={()=> {handleDeletePreview(idPreview); closeModal();}}>Delete</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ModalDeletePreview;
