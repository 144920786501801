import React, { useState } from 'react';
import './Register.scss'
import Agencies from './images/Agencies.jpeg'
import Email from './images/Email.png'
import LogoLogin from './images/LogoLogin.png'
import CheckMail from './images/mail_icon.svg'
import { Box, Typography, Button } from '@mui/material';
import { display } from '@mui/system';

const Register = () => {
  const [email, setEmail] = useState('');
  const [domainError, setDomainError] = useState('');
  const [sendEmail, setSendEmail] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar el dominio del correo electrónico
    const domain = email.split('@')[1];
    if (domain !== 'xaxis.com') {
      setDomainError('El dominio del correo electrónico debe ser "xaxis.com"');
      return;
    }


    // Enviar el correo electrónico al backend de Laravel
    fetch(`${process.env.REACT_APP_API_URL}/api/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      },
      body: JSON.stringify({
        "email": email
      }),
    })
      .then(response => response.json())
      .then(data => {
        // Aquí puedes manejar la respuesta del backend
        console.log(data);
        setSendEmail(data.sendEmail)
      })
      .catch(error => {
        // Aquí puedes manejar los errores de la solicitud
        console.error(error);
      });
  };

  return (
    <>
      <Box className="Register">
        <Box className="Register__box">
          <Box className="Register__box-logo"  >
              <img src={LogoLogin}/>
              <Typography className='signin' sx={{display: sendEmail ?  "none" : "block" }} >
                Sign in
              </Typography>
              <Typography sx={{display: sendEmail ?  "none" : "block" }} >
                Please share your GroupM e-mail address to login, you will get a smart link to access
              </Typography>
          </Box>
          {
            sendEmail ? 
            <Box className="Register__box-sendEmail">
              <Box className="great">
                <img src={CheckMail}/>
                <Typography>Great!</Typography>
              </Box>
              <Typography>Please check your inbox, we sent you a smartlink to access</Typography>
            </Box>
            :
            <form onSubmit={handleSubmit}>
                <label className='Register__box-form'>
                  <Box className="Register__box-form-email">
                    <img src={Email}/>
                  </Box>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                </label>
                {domainError && <p style={{ color: 'red' }}>{domainError}</p>}
                <button className='Register__box-submit' type="submit">SEND</button>
            </form>
          }
          <Box className="Register__box-footer">
            <img src={Agencies}/>
            <Typography>
              Need help signing? please send us an email at <a href='mailto:xaxemeacreativestudiosteam@xaxis.com'>xaxemeacreativestudiosteam@xaxis.com</a>
            </Typography>
          </Box>

        </Box>
      </Box>

    
    </>

  );
};

export default Register;
